///main color
$primary-color: #347fb5;
$secondary-color: #0490e1;

///overwrite bootstrap variable
$primary:    $primary-color;
$secondary:  $secondary-color;


// size override
$content-max-width:750px;
$content-max-width-xxl:850px;

$title-weight: 800;
$navbar-nav-link-padding-x: 1rem;

$main-margin: 2rem;

// 按需导入 Bootstrap 的变量和函数
@import '../../node_modules/bootstrap/scss/bootstrap-utilities.scss';
@import '../../node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import '../../node_modules/bootstrap/scss/_accordion.scss';
@import '../../node_modules/bootstrap/scss/_transitions.scss';
@import '../../node_modules/bootstrap/scss/_nav.scss';
@import '../../node_modules/bootstrap/scss/_navbar.scss';

@import '../../node_modules/bootstrap/scss/_alert.scss';
@import '../../node_modules/bootstrap/scss/_buttons.scss';

// $grid-breakpoints: map-merge(
//   $grid-breakpoints,
//   (
//     'xxxl': 1600px,
//   )
// );

// @import "bootstrap";


///colors
$brand-color :#3f6f95;

.axis-light{
    --hr-color: #{$gray-500};
    --main-nav-color:#{$gray-700};
    --main-title: #{$gray-900}; 
    --sub-title: #{$gray-800};
    --summary: #{$gray-700};
    --toc:#{$gray-600};

    --extra-info: #{$gray-500};

    --some-block-bg: #{ $gray-200};
    --some-block-title-bg: #{$gray-300};
    --copyright: #{$gray-600};

    --tag-cloud: #{$gray-700};
    --tag-cloud-hover: #{$primary-color};

    --waline-theme-color: #{$primary-color};
    --waline-active-color: #{$secondary-color};

    --content-max-width: #{$content-max-width};
}

.axis-dark{
    --hr-color: #{$gray-400};
    --main-nav-color:#{$gray-400};
    --main-title: #{$gray-200}; 
    --main-title-hover: #{$gray-300}; 
    --sub-title: #{$gray-300};

    --summary: #{$gray-400};
    --toc:#{$gray-500};

    --extra-info: #{$gray-600};
    --some-block-bg: #{ $gray-700};
    --some-block-title-bg: #{$gray-700};
    --copyright: #{$gray-400};

    --tag-cloud: #{$gray-300};
    --tag-cloud-hover: #{$gray-400};

    --waline-theme-color: #{$primary-color};
    --waline-active-color: #{$secondary-color};
}

///light mode colors
:root {
    @extend .axis-light;

    @include media-breakpoint-up(xxl) {
        --content-max-width: #{$content-max-width-xxl};
    }
}

///dark mode
// @media (prefers-color-scheme: dark) {
//     :root {
//         @extend .axis-dark;
//     }
// }


///dark mode
[data-bs-theme="dark"] {
    @extend .axis-dark;
}

@mixin post-hs{
    h1{
        font-size: 2rem;
    }
    h2{
        font-size: 1.8rem;
    }
    h3{
        font-size: 1.6rem;
    }
    h4{
        font-size: 1.4rem;
    }
    h5{
        font-size: 1.2rem;
    }
    h6{
        font-size: 1rem;
    }
}



///html base elements
body, html {
    margin: 0;
    font-family: "Noto Sans CJK SC", "Source Han Sans SC", "Source Han Sans CN", "Noto Sans SC", "PingFang SC", "Microsoft YaHei", sans-serif;
    font-size: 16px;

    @include media-breakpoint-up(lg){
        /**firefox scrollbar **/
        scrollbar-color: $primary-color;

        /* 隐藏滚动条上下箭头 */
        &::-webkit-scrollbar-button {
            display: none;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: rgba(var(--bs-tertiary-bg-rgb),var(--bs-bg-opacity));
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color;  /* 这里使用你的$primary-color */
            border-radius: 5px;         /* 为滑块添加圆角 */
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: darken($primary-color, 10%);  /* 滚动条悬浮时颜色稍微加深 */
        }
    }
    
}

// .wf-active body {
//     font-family: "Noto Sans SC", "Microsoft YaHei", sans-serif;
//   }

// :lang(en-US) {
//     font-family: "Helvetica Neue", Roboto, "Segoe UI", SegoeUI, Helvetica, Arial, sans-serif;
// }

h1,h2,h3,h4,h5,h6{
    font-weight: 600;
}

.invisible {
    visibility: hidden;
}

a {
    text-decoration: none;
    color: $primary-color;
    &:hover {
        color: $secondary-color;
        text-decoration: none;
        // text-decoration-color: rgba($color: $secondary-color, $alpha: 0.8);
    }
}

img{
    max-width: var(--content-max-width);
    margin: 0rem auto;
}

///post menu scorll
/// 用于保持文章概览滚动时的位置正确
:target::before {
    content: "";
    display: block;
    height: 60px; /* 导航栏的高度 */
    margin-top: -60px;
    visibility: hidden;
}

///主要容器高度--real-vh是js计算出来的值
.container{
    min-height: calc(var(--real-vh, 1vh) * 100 - 56px - 8em);
}

.content-container{
    margin-top : $main-margin;
    @include media-breakpoint-up(lg) {
        margin-top: 6rem;
    }
}

///default hr color
hr{
    color:var(--hr-color)
}
///end of base elements


///overwrite bootstrap class

.btn-primary{
    --bs-btn-hover-bg:#{$secondary-color};
    --bs-btn-hover-border-color:#{shade-color($secondary-color, 10%)};
}

.btn{
    &:hover{
        text-decoration: none;
    }
}

.main-nav{
     z-index: 99; 
    .navbar-brand{
        color: $brand-color;
    }
    color: var(--main-nav-color);

    .nav-link.active {
        font-weight: 600;
        @include media-breakpoint-up(lg) {
            // border-bottom: 4px solid $primary-color;
            font-weight: 800;
            
        }
        color: var(--main-nav-color);

    }  
    a{
        cursor: pointer;
        &:hover{
            text-decoration: none;
        }
    }
    z-index: 10;
}
///end of overwrite bootstrap class

////wormhole-light
.wormhole-wrapper {
    display: flex;
    align-items: center;
    .wormhole {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        margin: auto 8px;
        display: inline-block;
        color: #131314;
        background-color: #131314;
        box-shadow: 0 0 0 2px #4a4b4d, 0 0 0 4px #6a6b6c, 0 0 0 6px #a2a4a7;
    }
    span {
        font-size: 0.6rem;
        color: var(--copyright);
    }
}
/// end of wormhole-light

/// copyright footer
/// index page
.copyright-index{
    z-index: 3;
    color: $gray-600;
    font-size: 0.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 0.5rem 0;
    a {
        
        color: $gray-600;
        &:hover {
            color: $gray-500;
        }
    }
    // .site-title{
    //     margin-left: 0.4em;
    // }
    .dot{
        margin: 0 0.2em;
    }  
}

.copyright{
    @extend .copyright-index;
    color: var(--copyright);
    a {
        color: $primary-color;
        font-weight: 400;
        &:hover {
            color: darken($color: $primary-color, $amount: 10%);
        }
    }
    width: 80%;

    @include media-breakpoint-up(sm) {
        width: 100%;
    }
    
}
/// end of copyright

////home page////
.home-page {
    background-image: url('/img/blog_cover.jpg');
    background-size: cover;
    background-position: center center;
    height: calc(var(--real-vh, 1vh) * 100);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content-center {
        text-align: center;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1{
            color: white;
            font-weight: 800;
            font-size: calc(3.2rem + 2.5vw); 
            letter-spacing: 1.3rem;
            margin-right: -1.3rem;
            opacity: 0.93;
        }

        h5 {
            color: rgba($color: $gray-200, $alpha: 0.85);
            font-weight: 400;
            letter-spacing: 0.5rem;
            margin-right: -0.5rem;
            font-size: calc(1rem + 0.2vw); 
        }


        z-index: 2;
    }

    .enter-btn{
        margin-top: 3em;
        font-size: 1.1em;
        background-color: transparent;
        // border: 2px solid rgba($color: $gray-400, $alpha: 0.5);
        color: $gray-200;
        animation: breathe-move 3s infinite;
    }

    .overlay {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.15); /* 半透明背景 */
      }

      ///底部导航
      footer{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        .nav {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9rem;
            .nav-item {
                list-style-type: none;
                .nav-link {
                    display: inline-block;
                    text-decoration: none;
                    font-size: 1.05em;
                    color: rgba($gray-400, 0.7);
                    letter-spacing: 0.2em;
                    font-weight: 800;
                    &:hover {
                        // text-decoration: underline;
                        color: rgba($gray-500, 0.7);
                    }
    
                }
            }
            .nav-item-split {
                color: rgba($gray-400, 0.7);
                display: inline-block;
            }
        }
      }
      
}
///end of home page

///list page
.common-list{
    margin: $main-margin;
    .posts-list{
        @include post-hs;
        h1,h2,h3,h4,h5,h6{
            margin: 0.5em 0;
        }
        max-width: var(--content-max-width);
        margin: 0  auto ;
        .post-item{

            // width: 100%;
            .item-title {
                color: var(--main-title);
                a{
                    color: var(--main-title);
                    font-weight: 800;
                }
            } 

            .item-body{
                color: var(--summary);
                margin: 1.2rem 0 0 0;
                letter-spacing: 0.01rem;
                line-height: 1.8;
                font-size: 1.1rem;
                @include media-breakpoint-up(md) {
                    display: flex;
                    align-items: center;
                }
                i{
                    font-size: 0.8em;
                }
                a{
                    color: var(--summary);
                    text-decoration: none;
                }
                img{
                    width: 100%;
                    max-height: 320px;
                    object-fit: cover;
                    object-position: center;
                    padding: 8px 0 0 0;
                    @include media-breakpoint-up(md) {
                        padding: 12px 0 12px 24px;
                        width: 210px;
                        min-width: 210px;
                        height: 140px;
                        
                    }
                }
                
                
            }

            hr{
                margin: 2rem 0;
            }

            &.secondary{
                .item-title {
                    color: var(--sub-title);
                    a{
                        color: var(--sub-title);
                        font-weight: 800;
                    }
                }

                .post-extra{
                    margin: 0.5rem 0;
                   color: var(--extra-info);
                   .extra-info{
                       color: var(--extra-info);
                   }
                }
            }
        }

        
    }
}

///post extra info
.post-extra{
    margin: 0.5rem 0;
   color: var(--extra-info);
   .extra-info{
       color: var(--extra-info);
   }
}
///end of post extra info

///pagination///
.pagination{
    margin: $main-margin 0;
    .pagination-inner{
        max-width: var(--content-max-width);
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        .pre{
            padding-left: 0;
            padding-right: 0;
            margin: 0.5em 0;
        }

        .next{
            padding-left: 0;
            padding-right: 0;
            margin: 0.5em 0;
        }

        .pagination-numbers{
            flex-grow: 0;       /* 该元素不会增长 */
            flex-shrink: 1;     /* 如果需要的话，该元素会缩小 */
            flex-basis: auto;   /* 默认大小根据内容来决定，也可以设定一个固定值如20px */

            .pagination-number{
                margin: 0 0.3rem;
            }
        }
    }
}


////page-footer////
.page-footer{
    margin-top: 4em;
    display: flex;
    justify-content: center;
}

///post
.post{
    margin: $main-margin;
    display: flex;
    justify-content: center;
    .left-side{
        flex-grow: 1;
        max-width: 200px;

    }
    .right-side{
        flex-grow: 1;
        max-width: 200px;
        min-width:100px;
        .article-toc{
            position: sticky;
            top: 72px;
            z-index: 1020;
            color: var(--toc);
            font-size: 0.9em;
            margin-top: 7rem;
            margin-left: 1em;
            .toc-card{
                padding: 8px;
                border: solid var(--some-block-bg);
                h5{
                    padding-left: 0.3em;
                }
                ul{
                    list-style: none;
                    list-style-type: none;
                    padding-left: 0; 
                    li{
                        margin: 0.3em 0;
                    }
                }
                a{
                    color: var(--toc);
                    padding-left: 0.4em;
                    &:hover{
                        text-decoration: underline;
                        color: $secondary-color;
                    }
                    border-left: 2px solid transparent;
                }
                .active{
                    color: $primary-color;
                    border-left: 2px solid $primary-color;
    
                }
            }
            
        }
    }

    .small-toc{
        margin-top: 1rem;
        .accordion-button{
            padding:0.6em;
            background-color: var(--some-block-bg);
        }
        .accordion-button:not(.collapsed){
            background-color: var(--some-block-bg);
        }
        .accordion-button:focus{
            box-shadow: none;
        }
        .article-toc-small{
            ul{
                list-style: none;
                list-style-type: none;
                padding-left:0.5em 
            }
        }
    }


    .post-content{
        max-width: var(--content-max-width);
        width: 100%;
        margin-top: 2rem;

        .post-title{
            font-weight: $title-weight;
        }
        .post-subtitle{
            margin: 1rem 0 0 0;
            color: var(--extra-info);
            display: inline-block;
        }
        .post-tags{
            margin: 0 0 0 1rem;
            display: inline-block;
        }
        .post-body{
            margin: 1rem 0;
            letter-spacing: .01rem;
            line-height: 1.8;
            font-size: 1.1rem;
            color: var(--summary);

            @include post-hs;

            .post-summary{
                font-size: 0.9em;
                opacity: 0.8;
            }

            h1,h2,h3,h4,h5,h6{
                margin: 1em 0;
            }
            a:hover{
                text-decoration: underline;
            }
        }
    }

    .posts-photo-gallery{
        .post-photo-gallery{
            margin-top: 16px;
            .post-photos{
                clear: both;
                .post-photo-item{
                    // float: left;
                    width: 100%;
                    padding: 4px;
                    @include media-breakpoint-up(md) {
                        width: 25%;
                    }
                    img{
                        width: 100%;
                    }
                }
            }

        }
    }
}
///end of post

.axis-gallery-image{
    display: flex;
    width: 98%;
    img{
        width: 100%;
    }
}

.axis-gallery{
    max-width: var(--content-max-width);
    width: 100%;
    
    .axis-gallery-inner{

        figcaption{
            font-size: 0.8rem;
            color: var(--extra-info);
            margin: 0 0.5em;
        }

        hr{
            margin: 0.2em;
        }

        .axis-gallery-images{
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap; /* 允许换行 */
            .axis-gallery-image{
                margin: 0.5em auto;
            }

        }
    }
}
///end of gallery



///tags terms.html
.tags-container{
    
    .tags{
        margin: $main-margin;   
        .tag-list{
            margin: 2rem auto 0 auto;
            max-width: var(--content-max-width);
            width: 100%;
            list-style-type: none;
            padding-left: 0; 
            .tag-list-item{
                display: inline-block;
                font-size: 1.2rem;
                margin: 0.1em 0.5em;
                a{
                    // color: var(--tag-cloud);
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
///end of tags


///Timeline
.timeline{
    margin: $main-margin;
    .timeline-header{
        margin: 2rem auto 0 auto;
        max-width: var(--content-max-width);
        width: 100%;
        text-align: right;
        color:var(--summary);
    }
    .timeline-footer{
        margin: 2rem auto 0 auto;
        max-width: var(--content-max-width);
        width: 100%;
        color:var(--extra-info);
    }
    .timeline-group{
        
        margin: 0 auto;
        max-width: var(--content-max-width);
        width: 100%;
        

        ul{
            list-style-type: none;
            padding-left: 0;
        }

        .year{
            margin: 1em 0;
        }

        .month-group{
            font-size: 1.2em;
            flex-wrap: wrap;
            margin: 0.8em 0;
            .month{
                margin: 0.2em 2em 1em 0;
                min-width: 3em;
            }
        }

        .timeline-item{
            letter-spacing: .01rem;
            margin: 0.2em 0;
          
            .timeline-link{
                margin-right: 0.8em;
            }

            .timeline-date{
                color: var(--extra-info);
                font-size: 0.8rem;
            }
        }

    }
}
 
/// end of thimeline


///quote block style
.post-body{
    blockquote{
        width:98%;
        margin: 1.5rem auto;
        font-family:Open Sans;
        font-style:italic;
        padding:1.2em 30px 1.2em 55px;
        border-left:8px solid $primary-color;
        line-height:1.6;
        position: relative;
        background:var(--some-block-bg);
      
        &::before{
          font-family:Arial;
          content: "\201C";
          color:$primary-color;
          font-size:4em;
          position: absolute;
          left: 10px;
          top:-10px;  
        }
      
        &::after{
          content: '';
        }
      
        footer{
          font-size: 1rem;
          text-align: right;
          span{
              margin: 0 0.2em;
          }
        }
      }
}


///end of quote block style

///code block style
/// inlinecode
code {
    padding: 0.2em 0.4em;
    margin: 0 0.5em;
    background-color:var(--some-block-bg);
    border-radius: 4px;
}


// pre {
//     margin-top: 1em;
//     margin-bottom: 1em;
//     overflow: auto;
//     background-color: var(--some-block-bg);
//     padding: 1em;
//     border-radius: 4px;
//     code{
//         padding: 0;
//         margin: 0;
//     }
// }

    .code-toolbar{
        border-radius: 4px 4px 0 0;
        display: flex;
        justify-content: space-between;
        background-color: var(--some-block-title-bg);
        color: var(--summary);
        padding: 0.2em 1em;
        a{
            color: var(--summary);
            cursor: pointer;
            flex-grow: 1;
            text-align: right;

        }

        span{
            flex-grow: 1;
        }
    }
pre{
    // background-color: var(--some-block-bg) !important;
    // border-radius: 4px;
    margin: 0 0 0.5em 0 !important;
    code{
        padding: 0;
        margin: 0;
    }
    border-radius: 0 0 4px 4px !important;
}

///end of code block

///search box
.search-box{

    .search-text {
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 0; 
            padding: 0;
            transition: width 0.4s; 
            border: none;
            visibility: hidden;
            opacity: 0;
        }
    }

    .search-text.expanded {
        padding: .375rem .75rem;
        display: block;
        width: 200px;
        visibility: visible;
        opacity: 1;
    }

    .search-btn{
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}
/// end of search box
.search-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 9;
    -webkit-backdrop-filter: blur(10px); /* 为Safari等加上-webkit-前缀 */
    backdrop-filter: blur(10px);
}
/// dark mode
#dark-mode-toggle{
    cursor: pointer;
    &:hover{
        animation: rotate 0.4s linear;
    }
}
/// end of dark mode


/// walline
.waline-commonts{
    margin: $main-margin 0;
    .waline-wrapper{
        margin: 2rem auto 0 auto;
        max-width: var(--content-max-width);
        width: 100%;
        .wl-panel{
            margin: 0;
            border-radius: 0
        }
    }
}



///animations
@keyframes breathe-move {
    0% {
      opacity: 0.3;
      transform: translateX(0);
    }
    50% {
      opacity: 1;
      transform: translateX(10px);
    }
    100% {
      opacity: 0.3;
      transform: translateX(0);
    }
  }

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}




